import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const ImageText = loadable(() => import('./modules/imageText'));
const VideoText = loadable(() => import('./modules/videoText'));
const PageBanner = loadable(() => import('./modules/pageBanner'));
const IntroBlock = loadable(() => import('./modules/introBlock'));
const SingleColText = loadable(() => import('./modules/singleColText'));
const IconCardCarousel = loadable(() => import('./modules/iconCardCarousel'));
const StatsBlock = loadable(() => import('./modules/statsBlock'));
const TestimonialsBlock = loadable(() => import('./modules/testimonialsBlock'));
const LogoBlock = loadable(() => import('./modules/logoBlock'));
const TwoColText = loadable(() => import('./modules/twoColText'));
const ImageBlock = loadable(() => import('./modules/imageBlock'));
const BioCarousel = loadable(() => import('./modules/bioCarousel'));
const IconCardGrid = loadable(() => import('./modules/iconCardGrid'));
const ImageTextCarousel = loadable(() => import('./modules/imageTextCarousel'));
const VideoBlock = loadable(() => import('./modules/videoBlock'));
const ContactMap = loadable(() => import('./modules/contactMap'));
const JobFeed = loadable(() => import('./modules/jobFeed'));
const PageNav = loadable(() => import('./modules/pageNav'));
const Stats = loadable(() => import('./modules/stats'));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case 'pageBanner':
        return <PageBanner {...item} />;
      case 'imageText':
        return <ImageText {...item} />;
      case 'videoText':
        return <VideoText {...item} />;
      case 'introBlock':
        return <IntroBlock {...item} />;
      case 'singleColText':
        return <SingleColText {...item} />;
      case 'iconCardCarousel':
        return <IconCardCarousel {...item} />;
      case 'statsBlock':
        return <StatsBlock {...item} />;
      case 'testimonialsBlock':
        return <TestimonialsBlock {...item} />;
      case 'logoBlock':
        return <LogoBlock {...item} />;
      case 'twoColText':
        return <TwoColText {...item} />;
      case 'imageBlock':
        return <ImageBlock {...item} />;
      case 'bioCarousel':
        return <BioCarousel {...item} />;
      case 'iconCardGrid':
        return <IconCardGrid {...item} />;
      case 'imageTextCarousel':
        return <ImageTextCarousel {...item} />;
      case 'videoBlock':
        return <VideoBlock {...item} />;
      case 'contactMap':
        return <ContactMap {...item} />;
      case 'jobFeed':
        return <JobFeed {...item} />;
      case 'pageNav':
        return <PageNav {...item} />;
      case 'stats':
        return <Stats {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
