import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import EnquireModal from "../components/links/enquireModal";

const IndexPage = ({ data: { page } }) => {
  const { pageModules, config, seo } = page || {};

  return (
    <>
      <Layout {...config}>
        <PageMeta {...seo} />
        {pageModules && <ModuleZone {...pageModules} />}
      </Layout>

      {/* <form name="application-form" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="application-form" />
        <div>
          <label>Full Name:</label>
          <input type="text" name="fullName" />
          <label>Email Address:</label>
          <input type="email" name="email" />
          <label>Conact number</label>
          <input type="text" name="contactNumber" />
          <label>Message</label>
          <input type="text" name="message" />
          <label>conact method</label>
          <input type="text" name="contactMethod" />
        </div>
        <div>
          <label>File Upload (select file):</label>
          <textarea type="file" name="attachment" />
        </div>
        <button type="submit">Send</button>
      </form> */}
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      config {
        ...PageSettings
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
